import useUserAgentData from "decentraland-gatsby/dist/hooks/useUserAgentData"
import env from "decentraland-gatsby/dist/utils/env"

import { DEFAULT_PLATFORM, DESKTOP_SUPPORT_PLATFORMS } from "./useJumpInTarget"

export default function useDesktopTarget() {
  const data = useUserAgentData({ platform: DEFAULT_PLATFORM })

  if (data.platform === DEFAULT_PLATFORM) {
    return [undefined, true] as const
  }

  if (data.mobile || !DESKTOP_SUPPORT_PLATFORMS.has(data.platform)) {
    return [false, false] as const
  }

  const target =
    (data.platform === "macOS" || data.platform === "Mac OS") &&
    env("DESKTOP_MACOS_TARGET")
      ? env("DESKTOP_MACOS_TARGET")
      : env("DESKTOP_WINDOWS_TARGET") || false

  return [target, false] as const
}
