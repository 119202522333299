import React from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import Link from "decentraland-gatsby/dist/components/Text/Link"
import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import useUserAgentData from "decentraland-gatsby/dist/hooks/useUserAgentData"
import { navigate } from "decentraland-gatsby/dist/plugins/intl/utils"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import env from "decentraland-gatsby/dist/utils/env"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { useMobileMediaQuery } from "decentraland-ui/dist/components/Media/Media"

import Layout from "../components/Layout/Layout"
import PlayMenu from "../components/Layout/PlayMenu"
import SendToDesktopModal from "../components/Modal/SendToDesktopModal"
import useAppStore from "../hooks/useAppStore"
import useDesktopTarget from "../hooks/useDesktopTarget"
import useMsStore from "../hooks/useMsStore"
import appleLogo from "../images/apple-logo.svg"
import chromeLogo from "../images/chrome-logo.svg"
import microsoftLogo from "../images/microsoft-logo.svg"
import { SegmentEvent } from "../modules/segment"

import "./download.css"

function navigateHome() {
  navigate("/")
}

export default function DownloadPage(props: any) {
  const isMobile = useMobileMediaQuery()
  const l = useFormatMessage()
  const agentData = useUserAgentData()
  const msLink = useMsStore()
  const appLink = useAppStore()
  const handleClick = useTrackLinkContext()
  const [target] = useDesktopTarget()

  return (
    <Layout
      {...props}
      isFullscreen
      hideFooter={true}
      rightMenu={<PlayMenu hideJumpIn />}
      className={TokenList.join([props.className, "layout__navbar--fixed"])}
    >
      <Head
        title={l("page.download.title") || ""}
        description={l("page.download.description") || ""}
        image="https://decentraland.org/images/decentraland.png"
      />
      <div className="DownloadPage">
        <SendToDesktopModal open={isMobile} onClose={navigateHome} />
        {!isMobile && (
          <div className="DownloadModal">
            <div className="DownloadModal__Content">
              <div className="DownloadModal__Wrapper">
                <div>
                  <h1>
                    Download <br />
                    <strong>Decentraland</strong>
                  </h1>
                </div>
                <div style={{ marginTop: "24px", position: "relative" }}>
                  <Button
                    primary
                    size="large"
                    as={Link}
                    target="_blank"
                    onClick={handleClick}
                    data-event={SegmentEvent.Download}
                    data-place="download"
                    className="DownloadModal__DownloadButton"
                    loading={!target}
                    disabled={!target}
                    href={target || "#"}
                  >
                    {`Download for ${
                      (agentData.platform === "macOS" ||
                        agentData.platform === "Mac OS") &&
                      env("DESKTOP_MACOS_TARGET")
                        ? "macos"
                        : "windows"
                    }`}
                  </Button>
                </div>
              </div>

              {(env("DESKTOP_MICROSOFT_STORE_TARGET") ||
                env("DESKTOP_MACOS_STORE_TARGET")) && (
                <div className="DownloadModal__Wrapper">
                  <div>
                    <small>Also available</small>
                    <div className="DownloadModal__DownloadButtonsWrapper">
                      {env("DESKTOP_MICROSOFT_STORE_TARGET") && (
                        <div className="DownloadModal__DownloadButtonContainer">
                          <Button
                            as={Link}
                            target="_blank"
                            href={msLink}
                            onClick={handleClick}
                            data-event={SegmentEvent.MicrosoftStore}
                            data-place="download"
                          >
                            <img
                              src={microsoftLogo}
                              width="40"
                              height="40"
                              className="microsoftLogo"
                            />
                            <br />
                            <small>Microsoft Store</small>
                          </Button>
                        </div>
                      )}
                      {env("DESKTOP_MACOS_STORE_TARGET") && (
                        <div className="DownloadModal__DownloadButtonContainer">
                          <Button
                            as={Link}
                            target="_blank"
                            href={appLink}
                            onClick={handleClick}
                            data-event={SegmentEvent.AppStore}
                            data-place="download"
                          >
                            <img
                              src={appleLogo}
                              width="40"
                              height="40"
                              className="microsoftLogo"
                            />
                            <br />
                            <small>MacOs</small>
                          </Button>
                        </div>
                      )}
                      <div className="DownloadModal__DownloadButtonContainer">
                        <Button
                          as={Link}
                          target="_blank"
                          href="https://decentraland.org/play/"
                          onClick={handleClick}
                          data-event={SegmentEvent.ExploreOnWeb}
                          data-place="download"
                        >
                          <img
                            src={chromeLogo}
                            width="40"
                            height="40"
                            className="chromeLogo"
                          />
                          <br />
                          <small>Web browser</small>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!env("DESKTOP_MICROSOFT_STORE_TARGET") &&
                !env("DESKTOP_MACOS_STORE_TARGET") && (
                  <div>
                    <small style={{ fontSize: "17px", textTransform: "none" }}>
                      Want to play in your browser?{" "}
                      <Link
                        target="_blank"
                        href="https://decentraland.org/play/"
                        onClick={handleClick}
                        data-event={SegmentEvent.ExploreOnWeb}
                        data-place="download"
                      >
                        Explore on web
                      </Link>
                    </small>
                  </div>
                )}
            </div>
          </div>
        )}
        <div className="DownloadPage__Warning">
          Decentraland will never ask you to enter your wallet seed phrase
        </div>
      </div>
    </Layout>
  )
}
