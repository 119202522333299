import React, { useCallback } from "react"

import Input from "decentraland-gatsby/dist/components/Form/Input"
import useTrackContext from "decentraland-gatsby/dist/context/Track/useTrackContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import usePatchState from "decentraland-gatsby/dist/hooks/usePatchState"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Locale } from "decentraland-ui/dist/components/Language/Language"
import { Modal, ModalProps } from "decentraland-ui/dist/components/Modal/Modal"
import isEmail from "validator/lib/isEmail"

import { SegmentEvent } from "../../modules/segment"

import "./SendToDesktopModal.css"

export type SendToDesktopModalProps = Omit<ModalProps, "children"> & {
  lang?: Locale
}

export type SendToDesktopModalState = {
  email: string
  loading: boolean
  subscribed: boolean
  error: boolean
}

export default function SendToDesktopModal(props: SendToDesktopModalProps) {
  const l = useFormatMessage()
  const track = useTrackContext()
  const [state, patchState] = usePatchState<SendToDesktopModalState>({
    email: "",
    loading: false,
    subscribed: false,
    error: false,
  })

  const handleEmailChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      patchState({ email: event.currentTarget.value })
    },
    [patchState]
  )

  const handleSubmit = useCallback(() => {
    if (!isEmail(state.email) || state.loading) {
      return
    }

    patchState({ loading: true, error: false })

    track(SegmentEvent.SubscribeToMobile)

    fetch("https://subscription.decentraland.org/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: state.email,
        interest: "mobile-reminder",
      }),
    })
      .then((res) => {
        const error = res.status >= 400
        patchState({ loading: false, error, subscribed: !error })
      })
      .catch(() => patchState({ loading: false, error: true }))
  }, [patchState, track])

  return (
    <Modal
      {...props}
      className={TokenList.join(["SendToDesktopModal", props.className])}
    >
      <Modal.Content>
        {state.subscribed && (
          <>
            {l("index.mobile_modal.signed_up")}
            {props.onClose && (
              <Button
                primary
                style={{ width: "100%", marginTop: "2rem" }}
                onClick={props.onClose}
              >
                {l("index.mobile_modal.close")}
              </Button>
            )}
          </>
        )}
        {!state.subscribed && (
          <>
            {l("index.mobile_modal.description")}
            <Input
              type="email"
              placeholder={l("index.mobile_modal.placeholder") || ""}
              value={state.email}
              error={state.error}
              message={state.error && l("index.mobile_modal.sign_up_fail")}
              style={{ width: "100%", marginBottom: "1em" }}
              onChange={handleEmailChange}
            />
            <Button
              primary
              disabled={!isEmail(state.email)}
              loading={state.loading}
              style={{ width: "100%" }}
              onClick={handleSubmit}
            >
              {l("index.mobile_modal.sign_up")}
            </Button>
          </>
        )}
      </Modal.Content>
    </Modal>
  )
}
