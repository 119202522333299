import useUserAgentData from "decentraland-gatsby/dist/hooks/useUserAgentData"

export default function useJumpInTarget() {
  const data = useUserAgentData()

  if (data && !data.mobile && data.platform === "Windows") {
    return "ms-windows-store://pdp/?productid=9PHWZ7PJ32BR"
  }

  return "https://apps.microsoft.com/store/detail/9PHWZ7PJ32BR"
}
