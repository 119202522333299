import useUserAgentData from "decentraland-gatsby/dist/hooks/useUserAgentData"

export default function useJumpInTarget() {
  const data = useUserAgentData()
  if (data && !data.mobile && data.platform === "macOS") {
    return "https://github.com/decentraland/explorer-desktop-launcher/releases/latest/download/Decentraland.dmg"
  }

  return "https://github.com/decentraland/explorer-desktop-launcher/releases/latest/download/Decentraland.dmg"
}
