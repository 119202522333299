/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import Link from "decentraland-gatsby/dist/plugins/intl/Link"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import {
  Desktop,
  NotMobile,
  Tablet,
} from "decentraland-ui/dist/components/Media/Media"
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu/Menu"

import useJumpInTarget from "../../hooks/useJumpInTarget"
import discordBlack from "../../images/discord-black.svg"
import discordWhite from "../../images/discord-white.svg"
import { SegmentEvent } from "../../modules/segment"

import "./PlayMenu.css"

export type PlayMenuProps = {
  hideDiscord?: boolean
  hideJumpIn?: boolean
  onClickFallback?: () => void
}

export default function PlayMenu(props: PlayMenuProps) {
  const target = useJumpInTarget()
  const l = useFormatMessage()
  const loading = !target

  const handleClick = useTrackLinkContext()
  return (
    // <NotMobile>
    <Menu secondary stackable>
      {!props.hideJumpIn && (
        <Desktop>
          <Menu.Item style={{ marginLeft: "16px" }}>
            <Button
              primary
              size="tiny"
              as={Link}
              loading={loading}
              disabled={loading}
              href={target || "#"}
              data-event={SegmentEvent.ExploreOnWeb}
              data-place="menu"
              onClick={handleClick}
              className="jump-in"
            >
              {l("component.play_menu.jump_in")}
            </Button>
          </Menu.Item>
        </Desktop>
      )}
    </Menu>
    // </NotMobile>
  )
}
